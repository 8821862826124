<template>
    <div>
        <p>
            <span class="h3">
                {{ lesson.lesson.lesson.title }}
            </span>
        </p>

        <div
            v-if="lesson.lesson.lesson.type === 'algorithm'"
            class="profileBox profileBox--2"
        >
            <div v-if="lesson.homework && lesson.homework.status === 'not checked'">
                <p class="profileBox__title2">
                    Вы отправили алгоритм.
                </p>
                <p class="profileBox__text2 profileBox__text2--2">
                    <span v-if="!lesson.resetProgressByClient && lesson.isAccessToCheckAlgorithm">
                        Ожидайте, когда куратор проверит его.<br/>
                    </span>
                    <span v-if="(lesson.courseType === 'dk_3_0' && lesson.memberAccessLevel !== 'ВИП 3.0') || (lesson.courseType === 'dk_3_5' && lesson.memberAccessLevel !== 'VIP 3.5')">
                        Можете быть довольны собой, это последняя точка Вашего обучения! Поздравляем!<br/>
                    </span>
                    <span v-if="lesson.resetProgressByClient">
                        Ваша программа обнулена, опция проверки недоступна<br/>
                    </span>
                    <span v-if="!lesson.isAccessToCheckAlgorithm">
                        Опция проверки алгоритма недоступна<br/>
                    </span>
                </p>
            </div>

            <div v-else-if="lesson.homework && lesson.homework.status === 'declined'">
                <p class="profileBox__title2 colorRed">
                    Внимание!
                </p>
                <p class="profileBox__text2 profileBox__text2--2">
                    Куратор отправил Ваш алгоритм на доработку.
                </p>

                <HomeworkReplies />
            </div>

            <div v-else-if="lesson.homework && lesson.homework.status !== 'not checked'">
                <p class="profileBox__title2 colorGreen">
                    Поздравляем!
                </p>
                <p class="profileBox__text2 profileBox__text2--2">
                    Куратор проверил Ваш алгоритм.
                </p>

                <HomeworkReplies />
            </div>

            <div v-else>
                <div v-if="!isVisibleTest">
                    <p class="profileBox__title2">Алгоритм</p>
                    <p class="profileBox__text2">
                        Для завершения курса – составьте и отправьте нам Ваш личный алгоритм
                    </p>
                    <div class="algorithmStartBtns">
                        <Button
                            class="algorithmStartBtns__btn"
                            title="Я создам алгоритм с нуля"
                            :onClick="() => onStartTest()"
                        />
                        <Button
                            class="algorithmStartBtns__btn"
                            title="У меня есть готовый алгоритм"
                            :onClick="() => onStartCustomAnswer()"
                        />
                    </div>
                </div>

                <div v-if="isVisibleTest && algorithmTest && algorithmTest.length">
                    <div class="testProgress">
                        <div class="testProgressHeader">
                            <p class="title1 testProgressHeader__text">Прогресс</p>
                            <p class="title1 testProgressHeader__text">
                                {{ leftAnswersPhrase }}
                            </p>
                        </div>
                        <div class="progressRail testProgressRail">
                            <div
                                class="progressRail__total testProgressRail__total"
                                :style="'width: ' + (currentTestNumber / algorithmTest.length * 100) + '%'"
                            ></div>
                        </div>
                    </div>

                    <p
                        v-html="algorithmTest[currentTestNumber].ask"
                        class="profileBox__title2"
                    ></p>

                    <div v-if="algorithmTest[currentTestNumber].type === 'radio' || algorithmTest[currentTestNumber].type === 'checkbox'" class="form form--2 testForm">
                        <div
                            v-for="(item, index) in algorithmTest[currentTestNumber].options"
                            :key="item.answer"
                            class="formgroup"
                        >
                            <label class="formgroup__radioLabel">
                                <input
                                    class="input--checkbox"
                                    :id="item.value"
                                    :value="item.value"
                                    :name="item.name"
                                    :type="algorithmTest[currentTestNumber].type === 'checkbox' ? 'checkbox' : 'radio'"
                                    v-model="$data[algorithmTest[currentTestNumber].type === 'checkbox' ? 'testAnswerCheckbox' : 'testAnswerRadio']"
                                />
                                <span class="colorGray formgroup__radioLabel_text">
                                {{ item.answer }}
                            </span>
                            </label>
                        </div>

                        <div
                            v-if="algorithmTest[currentTestNumber].options[algorithmTest[currentTestNumber].options.length - 1].isCustom && (testAnswerCheckbox.includes(algorithmTest[currentTestNumber].options[algorithmTest[currentTestNumber].options.length - 1].value) || testAnswerRadio === algorithmTest[currentTestNumber].options[algorithmTest[currentTestNumber].options.length - 1].value)"
                            class="formgroup"
                        >
                            <textarea
                                rows="7"
                                :placeholder="algorithmTest[currentTestNumber].options[algorithmTest[currentTestNumber].options.length - 1].customInput.placeholder"
                                :id="testAnswerCustomInput"
                                :name="algorithmTest[currentTestNumber].options[algorithmTest[currentTestNumber].options.length - 1].customInput.name"
                                v-model="testAnswerCustomInput"
                            ></textarea>
                        </div>
                    </div>

                    <div
                        v-else-if="algorithmTest[currentTestNumber].type === 'file'"
                        class="form form--2 testForm"
                    >
                        <div class="form profileBox__form2">
                            <div class="formgroup">
                                <InputZone
                                    id="algorithmFileDropzone"
                                    :receivedFiles="files"
                                    :name="algorithmTest[currentTestNumber].name"
                                    :maxFiles="5"
                                    :maxSize="5"
                                    :onFilesUpdate="(e) => updateFiles(e)"
                                />
                            </div>

                            <div
                                v-if="algorithmTest[currentTestNumber].isCustom"
                                class="formgroup"
                            >
                                <textarea
                                    rows="7"
                                    :placeholder="algorithmTest[currentTestNumber].customInput.placeholder"
                                    :id="testAnswerCustomInput"
                                    :name="algorithmTest[currentTestNumber].customInput.name"
                                    v-model="testAnswerCustomInput"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <div
                        v-else
                        class="form form--2 testForm"
                    >
                        <div class="form profileBox__form2">
                            <div class="formgroup">
                                <textarea
                                    rows="7"
                                    :placeholder="algorithmTest[currentTestNumber].placeholder"
                                    :id="testAnswerInput"
                                    :name="algorithmTest[currentTestNumber].name"
                                    v-model="testAnswerInput"
                                ></textarea>
                            </div>

                            <div v-if="testType==='custom'" class="formgroup">
                                <InputZone
                                    :receivedFiles="files"
                                    :maxFiles="10"
                                    :maxSize="5"
                                    :onFilesUpdate="(e) => updateFiles(e)"
                                />
                            </div>
                        </div>
                    </div>

                    <div class="profileBox__btn2 testFooter">
                        <div>
                            <Button v-if="currentTestNumber" title="Назад" :onClick="() => previousAnswer()" />
                        </div>

                        <Button title="Дальше" :onClick="() => checkAnswer()" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Button from "@/views/components/Button/Button";
import Input from "@/views/components/Input/Input";
import TextArea from "@/views/components/TextArea/TextArea";
import InputZone from "@/views/components/InputZone/InputZone";
import HomeworkReplies from "@/views/components/HomeworkReplies/HomeworkReplies";

export default {
    name: "test",

    components: {
        Button,
        Input,
        TextArea,
        InputZone,
        HomeworkReplies,
    },

    async beforeMount() {
        await this.updateLessonStore();
        // await this.updateProgressStore();

        if (this.lesson.lesson.lesson.type !== 'algorithm') {
            this.$router.push(
                `/${this.$route.params.slug}/study/${this.$route.params.block_priority}/${this.$route.params.lesson_id}`
            );
        }
    },

    watch: {
        "$route.params.lesson_id": async function() {
            await this.updateLessonStore();

            this.isVisibleTest = false;
            this.testType = "";

            this.currentTestNumber = 0;
            this.testAnswerRadio = "";
            this.testAnswerCheckbox = [];
            this.testAnswerCustomInput = "";
            this.testAnswerInput = "";
            this.files = [];

            this.testData = {
                body: {},
                files: []
            };

            this.algorithmTest = [];
            this.algorithmCurrentApi = "";
        },
    },

    computed: {
        lesson: function() {
            return this.$store.getters.getLesson && this.$store.getters.getLesson.lesson;
        },

        leftAnswersPhrase: function() {
            const counter = this.algorithmTest.length - this.currentTestNumber;
            let phrase_1 = "Осталось";
            let phrase_2 = "вопросов";

            if (counter === 1) {
                phrase_1 = "Остался";
                phrase_2 = "вопрос";
            } else if (counter > 1 && counter < 5) {
                phrase_2 = "вопроса";
            }

            return `${phrase_1} ${counter} ${phrase_2}`;
        },
    },

    data() {
        return {
            isVisibleTest: false,
            testType: "",

            currentTestNumber: 0,
            testAnswerRadio: "",
            testAnswerCheckbox: [],
            testAnswerCustomInput: "",
            testAnswerInput: "",
            files: [],

            testData: {
                body: {},
                files: []
            },

            algorithmTest: [],
            algorithmCurrentApi: "",

            algorithmDefaultTest: [
                {
                    type: "radio",
                    ask: "Выберите ваш рынок",
                    options: [
                        {
                            answer: "Американский рынок",
                            value: "Американский рынок",
                            name: "market",
                        },
                        {
                            answer: "Российский рынок",
                            value: "Российский рынок",
                            name: "market",
                        },
                        {
                            answer: "Форекс",
                            value: "Форекс",
                            name: "market",
                        },
                        {
                            answer: "Рынок криптовалют",
                            value: "Рынок криптовалют",
                            name: "market",
                        },
                        {
                            answer: "Пока не торгую",
                            value: "Пока не торгую",
                            name: "market",
                        },
                        {
                            answer: "Другой рынок",
                            value: "Другой рынок",
                            name: "market",
                        },
                    ]
                },
                {
                    type: "checkbox",
                    ask: "Выберите свой стиль",
                    options: [
                        {
                            answer: "Краткосрочная",
                            value: "Краткосрочная",
                            name: "style_1",
                        },
                        {
                            answer: "Свинг трейдинг",
                            value: "Свинг трейдинг",
                            name: "style_2",
                        },
                        {
                            answer: "Среднесрочная",
                            value: "Среднесрочная",
                            name: "style_3",
                        },
                        {
                            answer: "Скальпинг",
                            value: "Скальпинг",
                            name: "style_4",
                        },
                        {
                            answer: "CarryTrade",
                            value: "CarryTrade",
                            name: "style_5",
                        },
                        {
                            answer: "Новостная торговля StraddleTrade",
                            value: "Новостная торговля StraddleTrade",
                            name: "style_6",
                        },
                        {
                            answer: "Долгосрочная",
                            value: "Долгосрочная",
                            name: "style_7",
                        },
                        {
                            answer: "Другое",
                            value: "Другое",
                            name: "style_custom",
                            isCustom: true,
                            customInput: {
                                placeholder: "Напишите свой вариант",
                                name: "style_custom_text",
                            }
                        },
                    ]
                },
                {
                    ask: "Укажите часы вашей работы за терминалом",
                    placeholder: "Часы работы за терминалом",
                    name: "time_to_work",
                },
                {
                    type: "checkbox",
                    ask: "Я не торгую, если",
                    options: [
                        {
                            answer: "Плохое настроение",
                            value: "Плохое настроение",
                            name: "when_no_trade_1",
                        },
                        {
                            answer: "Я пьян(а) или на веселе)",
                            value: "Я пьян(а) или на веселе)",
                            name: "when_no_trade_2",
                        },
                        {
                            answer: "Превысил(а) установленный уровень риска на день, неделю, месяц",
                            value: "Превысил(а) установленный уровень риска на день, неделю, месяц",
                            name: "when_no_trade_3",
                        },
                        {
                            answer: "В день выхода важных экономических показателей",
                            value: "В день выхода важных экономических показателей",
                            name: "when_no_trade_4",
                        },
                        {
                            answer: "Период банковских выходных",
                            value: "Период банковских выходных",
                            name: "when_no_trade_5",
                        },
                        {
                            answer: "В период окончания финансового года",
                            value: "В период окончания финансового года",
                            name: "when_no_trade_6",
                        },
                        {
                            answer: "В период начала финансового года",
                            value: "В период начала финансового года",
                            name: "when_no_trade_7",
                        },
                        {
                            answer: "Другое",
                            value: "Другое",
                            name: "when_no_trade_custom",
                            isCustom: true,
                            customInput: {
                                placeholder: "Напишите свой вариант",
                                name: "when_no_trade_custom_text",
                            }
                        },
                    ]
                },
                {
                    type: "radio",
                    ask: "Делаю разбор вчерашних сделок",
                    options: [
                        {
                            answer: "Да",
                            value: "Да",
                            name: "make_analyze_1",
                        },
                        {
                            answer: "Нет",
                            value: "Нет",
                            name: "make_analyze_2",
                        },
                    ]
                },
                {
                    ask: "Мои инструменты (что я торгую)",
                    placeholder: "Инструменты",
                    name: "what_to_trade",
                },
                {
                    ask: "Какой тип анализа использую",
                    placeholder: "Тип анализа",
                    name: "type_of_analyze",
                },
                {
                    type: "file",
                    ask: "Какие модели я торгую",
                    name: "patterns",
                    isCustom: true,
                    customInput: {
                        placeholder: "Или опишите свои стратегии в текстовом виде",
                        name: "patterns_custom_text",
                    }
                },
                {
                    ask: "Какие важные экономические новости я просматриваю в календаре",
                    placeholder: "Ваш ответ",
                    name: "important_news",
                },
                {
                    ask: "Делаю общий анализ рынка на таких таймфреймах",
                    placeholder: "Ваш ответ",
                    name: "analyze_on_timeframes",
                },
                {
                    ask: "Какие индикаторы я использую (укажите названия и настройки)",
                    placeholder: "Ваш ответ",
                    name: "indicators",
                },
                {
                    ask: "Сигналы для входа в рынок, т.е. когда я вхожу в рынок",
                    placeholder: "Ваш ответ",
                    name: "signals_to_enter",
                },
                {
                    type: "file",
                    ask: "Картинки ситуаций",
                    name: "situations",
                },
                {
                    type: "file",
                    ask: "Я торгую такую модель рынка на основе ценовых уровней",
                    name: "price_levels",
                    isCustom: true,
                    customInput: {
                        placeholder: "Или опишите свои стратегии в текстовом виде",
                        name: "price_levels_custom_text",
                    }
                },
                {
                    type: "checkbox",
                    ask: "Сигналом для входа в рынок для <span class='colorRed'>продажи (Sell)</span> инструмента на основе ценовых уровней, для меня является?",
                    options: [
                        {
                            answer: "Проторговка цены под уровнем после пробоя уровня (пробой уровня)",
                            value: "Проторговка цены под уровнем после пробоя уровня (пробой уровня)",
                            name: "signal_to_sell_1",
                        },
                        {
                            answer: "Проторговка цены под уровнем без пробоя (разворот от уровня)",
                            value: "Проторговка цены под уровнем без пробоя (разворот от уровня)",
                            name: "signal_to_sell_2",
                        },
                    ]
                },
                {
                    type: "file",
                    ask: "В картинках это выглядит так",
                    name: "pics_sell",
                },
                {
                    type: "checkbox",
                    ask: "Сигналом для входа в рынок для <span class='colorGreen'>покупки (Buy)</span> инструмента на основе ценовых уровней, для меня является?",
                    options: [
                        {
                            answer: "Проторговка цены над уровнем после пробоя уровня (пробой уровня)",
                            value: "Проторговка цены под уровнем после пробоя уровня (пробой уровня)",
                            name: "signal_to_buy_1",
                        },
                        {
                            answer: "Проторговка цены над уровнем без пробоя (разворот от уровня)",
                            value: "Проторговка цены под уровнем без пробоя (разворот от уровня)",
                            name: "signal_to_buy_2",
                        },
                    ]
                },
                {
                    type: "file",
                    ask: "В картинках это выглядит так",
                    name: "pics_buy",
                },
                {
                    ask: "Сигналом ложного пробоя для меня является",
                    placeholder: "Ваш ответ",
                    name: "signal_wrong_fall",
                },
                {
                    ask: "Максимально-допустимые риски для моего депозита (в %) на сделку, на день, в месяц",
                    placeholder: "Ваш ответ",
                    name: "max_risks",
                },
                {
                    type: "radio",
                    ask: "Соотношение потенциала прибыли к убытку равняется",
                    options: [
                        {
                            answer: "1:1",
                            value: "1:1",
                            name: "potential_1",
                        },
                        {
                            answer: "2:1",
                            value: "2:1",
                            name: "potential_2",
                        },
                        {
                            answer: "3:1",
                            value: "3:1",
                            name: "potential_3",
                        },
                        {
                            answer: "Другое",
                            value: "Другое",
                            name: "potential_custom",
                            isCustom: true,
                            customInput: {
                                placeholder: "Ваш ответ",
                                name: "potential_custom_text",
                            }
                        },
                    ]
                },
                {
                    type: "radio",
                    ask: "После входа в сделку на каждый ордер я устанавливаю StopLoss",
                    options: [
                        {
                            answer: "Да",
                            value: "Да",
                            name: "set_stoploss_1",
                        },
                        {
                            answer: "Нет",
                            value: "Нет",
                            name: "set_stoploss_2",
                        },
                    ]
                },
                {
                    type: "radio",
                    ask: "После входа в сделку на каждый ордер я устанавливаю TakeProfit",
                    options: [
                        {
                            answer: "Да",
                            value: "Да",
                            name: "set_takeprofit_1",
                        },
                        {
                            answer: "Нет",
                            value: "Нет",
                            name: "set_takeprofit_2",
                        },
                    ]
                },
                {
                    type: "radio",
                    ask: "В своей торговле я практикую частичную фиксацию объема ордера (закрываю прибыльную сделку частями)",
                    options: [
                        {
                            answer: "Да",
                            value: "Да",
                            name: "fix_volume_1",
                        },
                        {
                            answer: "Нет",
                            value: "Нет",
                            name: "fix_volume_2",
                        },
                    ]
                },
                {
                    type: "file",
                    ask: "Как это выглядит? Картинка",
                    name: "fix_volume_pics",
                },
                {
                    type: "radio",
                    ask: "Делаю обязательно работу над ошибками",
                    options: [
                        {
                            answer: "Да",
                            value: "Да",
                            name: "work_at_mistakes_1",
                        },
                        {
                            answer: "Нет",
                            value: "Нет",
                            name: "work_at_mistakes_2",
                        },
                    ]
                },
            ],

            algorithmCustomTest: [
                {
                    type: "radio",
                    ask: "Выберите ваш рынок",
                    options: [
                        {
                            answer: "Американский рынок",
                            value: "Американский рынок",
                            name: "market",
                        },
                        {
                            answer: "Российский рынок",
                            value: "Российский рынок",
                            name: "market",
                        },
                        {
                            answer: "Форекс",
                            value: "Форекс",
                            name: "market",
                        },
                        {
                            answer: "Рынок криптовалют",
                            value: "Рынок криптовалют",
                            name: "market",
                        },
                        {
                            answer: "Пока не торгую",
                            value: "Пока не торгую",
                            name: "market",
                        },
                        {
                            answer: "Другой рынок",
                            value: "Другой рынок",
                            name: "market",
                        },
                    ]
                },
                {
                    ask: "Напишите комментарий к алгоритму",
                    placeholder: "Комментарий",
                    name: "text",
                },
            ]
        };
    },

    methods: {
        onStartTest() {
            this.testType = "default";
            this.isVisibleTest = true;
            this.algorithmTest = this.algorithmDefaultTest;
            this.algorithmCurrentApi = "/programs/" + this.$route.params.slug + "/study/" + this.$route.params.block_priority + "/" + this.$route.params.lesson_id + "/answer/algorithm";
        },
        onStartCustomAnswer() {
            this.testType = "custom";
            this.isVisibleTest = true;
            this.algorithmTest = this.algorithmCustomTest;
            this.algorithmCurrentApi = "/programs/" + this.$route.params.slug + "/study/" + this.$route.params.block_priority + "/" + this.$route.params.lesson_id + "/answer/algorithm/custom";
        },

        async updateProgressStore() {
            const getProgress = await this.axios.get(
                "/programs/" + this.$route.params.slug + "/study/progress"
            );

            if (getProgress && getProgress.data && getProgress.data.result) {
                await this.$store.dispatch("setUserProgress", getProgress.data);
            } else {
                console.error(
                    "Ошибка при поиске основных блоков: " + getProgress.data.error
                );
                await this.$store.dispatch("clearUserProgress");
            }
        },

        async updateLessonStore() {
            const getLesson = await this.axios.get(
                "/programs/" +
                    this.$route.params.slug +
                    "/study/" +
                    this.$route.params.block_priority +
                    "/" +
                    this.$route.params.lesson_id
            );

            if (getLesson && getLesson.data && getLesson.data.result) {
                await this.$store.dispatch("setLesson", getLesson.data);
            } else {
                console.error("Ошибка при поиске урока: " + (getLesson.data.error || ""));
                await this.$store.dispatch("clearLesson");
                await this.$router.push("/" + this.$route.params.slug + "/study");

                if (getLesson.data && getLesson.data.error) {
                    await this.$store.dispatch("setAlert", {
                        alert: {
                            isActive: true,
                            type: "error",
                            text: getLesson.data.error,
                        },
                    });
                }
            }
        },

        async checkAnswer() {
            let value = [];
            if (this.testAnswerRadio) {
                value.push(this.testAnswerRadio);
            } else if (this.testAnswerCheckbox.length) {
                value = this.testAnswerCheckbox;
            } else if (this.testAnswerInput) {
                value.push(this.testAnswerInput);
            }

            if (value.length || ((this.algorithmTest[this.currentTestNumber].type === 'file' && this.files.length) || this.testAnswerCustomInput)) {
                value.forEach((element) => {
                    this.testData.body[document.getElementById(element).name] = element;
                });

                if (this.testAnswerCustomInput) {
                    this.testData.body[document.getElementById(this.testAnswerCustomInput).name] = this.testAnswerCustomInput;
                }

                if (this.files.length) {
                    if (this.testType === "custom") {
                        for (let i = 0; i < this.files.length; i++) {
                            this.files[i].field = "file-algo-" + i;
                        }

                        this.testData.files = this.files;
                    } else {
                        this.files[0].field = document.getElementById("algorithmFileDropzone").getAttribute("name");
                        this.testData.files.push(this.files[0]);
                    }
                }


                if (this.currentTestNumber < this.algorithmTest.length - 1) {
                    this.currentTestNumber++
                } else {
                    const getAlgorithmResponse = await this.axios.post(this.algorithmCurrentApi, this.testData);

                    this.testData = {
                        body: {},
                        files: []
                    };
                    await this.updateLessonStore();
                }

                this.clearInputs();
            } else {
                await this.$store.dispatch("setAlert", {
                    alert: {
                        isActive: true,
                        type: "error",
                        text: this.algorithmTest[this.currentTestNumber].type === 'radio' || this.algorithmTest[this.currentTestNumber].type === 'checkbox'
                            ? "Вы не выбрали ни одного ответа"
                            : this.algorithmTest[this.currentTestNumber].type === 'file'
                                ? "Вы ни загрузили ни одного файла"
                                : "Поле с ответом пустое"
                    },
                });
            }
        },

        async previousAnswer() {
            this.currentTestNumber--;

            if (this.algorithmDefaultTest[this.currentTestNumber].type === "radio" || this.algorithmDefaultTest[this.currentTestNumber].type === "checkbox") {
                for (let i = 0; i < this.algorithmDefaultTest[this.currentTestNumber].options.length; i++) {
                    const propName = this.algorithmDefaultTest[this.currentTestNumber].options[i].name;
                    if (this.testData.body[propName]) delete this.testData.body[propName];

                    if (this.algorithmDefaultTest[this.currentTestNumber].options[i].isCustom) {
                        delete this.testData.body[this.algorithmDefaultTest[this.currentTestNumber].options[i].customInput.name];
                    }
                }
            } else if (this.algorithmDefaultTest[this.currentTestNumber].type === "file") {
                this.testData.files = this.testData.files.filter(item => item.field !== this.algorithmDefaultTest[this.currentTestNumber].name);

                if (this.algorithmDefaultTest[this.currentTestNumber].isCustom) {
                    delete this.testData.body[this.algorithmDefaultTest[this.currentTestNumber].customInput.name];
                }
            } else {
                const propName = this.algorithmDefaultTest[this.currentTestNumber].name;
                if (this.testData.body[propName]) delete this.testData.body[propName];
            }


            this.clearInputs();
        },

        clearInputs () {
            this.testAnswerRadio = "";
            this.testAnswerCheckbox = [];
            this.testAnswerCustomInput = "";
            this.testAnswerInput = "";
            this.files = [];
        },

        async updateFiles(passedFiles) {
            this.files = passedFiles;
        }
    },
};
</script>

<style lang="scss">
@import "./algorithm.scss";
</style>
